<template>
  <div class="flex items-center gap-1 text-gray-400">
    <BaseTooltip>
      <template #target>
        <BaseButton type="blank" size="xxs" @press="$emit('share-news-item')">
          <ShareIcon class="h-4 w-4 text-gray-400 hover:text-gray-100" />
        </BaseButton>
      </template>
      <template #default>Click to copy the link</template>
    </BaseTooltip>
    <BaseMenu :items="folderItems" @action="actionHandler">
      <template #icon><FolderIcon class="h-4 w-4 text-gray-400 hover:text-gray-100" /></template>
    </BaseMenu>
    <BaseTooltip v-if="canDelete || canEdit">
      <template #target>
        <BaseButton type="blank" size="xxs" @press="$emit('delete')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 text-gray-400 hover:text-gray-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            v-if="canDelete"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </BaseButton>
      </template>
      <template #default>ADMIN - delete article for everyone because it is irrelevant/junk</template>
    </BaseTooltip>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { useWatchlistStore } from '@/stores/watchlists';
import { ShareIcon, FolderIcon } from '@heroicons/vue/20/solid';

const emitter = useEmitter();
const $http = useHttp();
const $watchlistStore = useWatchlistStore();

const props = defineProps({
  isStarred: Boolean,
  canDelete: Boolean,
  canEdit: Boolean,
  canGlobalStar: Boolean,
  newsItemId: Number
});

const emit = defineEmits(['toggle-starred']);

const folders = inject('maintainerFolders', []).value.sort((a, b) => a.name - b.name);

const selectedFolderName = inject('selectedFolderName', '');

const refreshNews = inject('refreshNews');

const folderModalOpen = inject('folderModalOpen', false);

const folderItems = computed(() => {
  let rs_folder = folders.map(folder => {
    const prefix = folder.name && folder.name == selectedFolderName.value ? '- ' : '+ ';
    return {
      label: prefix + folder.name,
      action: folder.name
    };
  });
  rs_folder = [
    { label: 'New Folder', action: 'add-new-folder' },
    { label: props.isStarred ? '★ Unstar' : '★ Star', action: 'toggle-starred' }
  ].concat(rs_folder);
  return rs_folder;
});

const actionHandler = async action => {
  if (action == 'toggle-starred') emit('toggle-starred');
  else if (action == 'add-new-folder') {
    folderModalOpen.value = true;
    emitter.$emit('news-item:add-to-new-folder', props.newsItemId);
  } else {
    const watchlist = folders.find(folder => folder.name == action);
    if (watchlist) {
      if (watchlist.name == selectedFolderName.value) {
        await $watchlistStore.deleteWatchlistEntity({
          watchlist_ids: [watchlist.id],
          entity: 'news_items',
          entity_id: props.newsItemId
        });

        refreshNews();
      } else
        await $watchlistStore.updateWatchlistEntity({
          watchlist_ids: [watchlist.id],
          entity: 'news_items',
          entity_id: props.newsItemId
        });
    }
  }
};
</script>
